export const fontPairings = [
  {
    id: 1,
    name: 'Default',
    title: 'font-playfair',
    body: 'font-sans',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 2,
    name: 'Modern',
    title: 'font-primary',
    body: 'font-sans',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 3,
    name: 'Classic',
    title: 'font-playfair',
    body: 'font-serif',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 4,
    name: 'Mono',
    title: 'font-mono',
    body: 'font-mono',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 5,
    name: 'Playful',
    title: 'font-fun',
    body: 'font-fun',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 6,
    name: 'Grotesk',
    title: 'font-grotesk',
    body: 'font-grotesk tracking-normal',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 7,
    name: 'Article',
    title: 'font-primary',
    body: 'font-serif',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 8,
    name: 'Crimson',
    title: 'font-crimson font-normal ',
    body: 'font-sans tracking-normal',
    plan: ['Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
];

export const fontSizes = [
  {
    id: 1,
    name: '1',
    className: 'xs',
    title: 'xs:text-4xl text-4xl',
    heading: 'text-xl',
    body: 'xs:text-xs tracking-tight text-sm ',
  },
  {
    id: 2,
    name: '2',
    className: 'sm',
    title: 'xs:text-4xl text-4xl',
    heading: 'text-xl',
    body: 'xs:text-sm tracking-tight text-sm',
  },
  {
    id: 3,
    name: '3',
    className: 'md',
    title: 'xs:text-4xl text-4xl',
    heading: 'text-xl',
    body: 'xs:text-base tracking-tight text-sm ',
  },
  {
    id: 4,
    name: '4',
    className: 'lg',
    title: 'xs:text-4xl text-4xl',
    heading: 'text-xl',
    body: 'xs:text-lg tracking-tight text-sm',
  },
  {
    id: 5,
    name: '5',
    className: 'xl',
    title: 'xs:text-4xl text-4xl',
    heading: 'text-xl',
    body: 'xs:text-xl tracking-tight text-sm ',
  },
  {
    id: 6,
    name: '6',
    className: 'xxl',
    title: 'xs:text-4xl text-4xl',
    heading: 'text-xl',
    body: 'xs:text-2xl tracking-tight text-sm',
  },
];

export const insideWidth = [
  {
    id: 1,
    name: 'Narrow',
    width: 512,
    padding: 40,
    innerPadding: 'p-12',
    quoteMargins: '-ml-7 -mr-7 pl-6 pr-7',
    title: 'xs:text-5xl text-4xl',
    body: 'xs:text-lg text-base leading-8',
    iconClass: 'w-4 h-6 border border-gray-500 rounded-sm',
  },
  {
    id: 2,
    name: 'Normal',
    width: 612,
    padding: 40,
    innerPadding: 'p-12',
    quoteMargins: '-ml-7 -mr-7 pl-6 pr-7',
    title: 'xs:text-5xl text-4xl',
    body: 'xs:text-lg text-base leading-8',
    iconClass: 'w-6 h-6 border border-gray-500 rounded-sm',
  },
  {
    id: 3,
    name: 'Wide',
    width: 726,
    padding: 32,
    innerPadding: 'xs:p-6 p-12',
    quoteMargins: 'xs:-ml-3 xs:-mr-3 xs:pl-3 xs:pr-4 -ml-7 -mr-7 pl-6 pr-7',
    title: 'xs:text-5xl text-4xl',
    body: 'xs:text-md text-lg leading-7',
    iconClass: 'w-8 h-6 border border-gray-500 rounded-sm',
  },
];

export const highlightColors = [
  {
    id: 1,
    name: 'Indigo',
    background: 'bg-indigo-500',
    text: 'text-indigo-500',
  },
  { id: 2, name: 'Red', background: 'bg-red-500', text: 'text-red-500' },
  { id: 3, name: 'Blue', background: 'bg-blue-500', text: 'text-blue-500' },
  { id: 4, name: 'Green', background: 'bg-green-500', text: 'text-green-500' },
  {
    id: 5,
    name: 'Yellow',
    background: 'bg-yellow-500',
    text: 'text-yellow-500',
  },
];

export const shapes = [
  {
    id: 1,
    name: 'Rectangle',
    iconClass: 'w-4 h-6 border border-gray-500 rounded-sm',
  },
  {
    id: 2,
    name: 'Square',
    iconClass: 'w-6 h-6 border border-gray-500 rounded-sm',
  },
];

export const layouts = [
  {
    id: 1,
    name: 'Left',
    iconClass: 'w-6 h-6 border border-gray-500 rounded-sm',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 2,
    name: 'Center',
    iconClass: 'w-6 h-6 border border-gray-500 rounded-sm',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 3,
    name: 'Sidebar',
    iconClass: 'w-6 h-6 border-l-8 border border-gray-500 rounded-sm',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 4,
    name: 'Article',
    iconClass: 'w-6 h-6 border rounded-sm border-gray-500 rounded-sm',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  /*   {id: 5, name: "Perell", iconClass: "w-6 h-6 border rounded-sm border-gray-500 rounded-sm", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]}, */
];

export const accentColors = [
  {
    id: 1,
    name: 'WhiteAccent',
    background: 'bg-white',
    text: 'text-gray-900',
    textLight: 'text-white',
    border: 'border-white',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 2,
    name: 'IndigoAccent',
    background: 'bg-indigo-500',
    text: 'text-indigo-500',
    textLight: 'text-indigo-200',
    border: 'border-indigo-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 3,
    name: 'GreenAccent',
    background: 'bg-green-500',
    text: 'text-green-500',
    textLight: 'text-green-200',
    border: 'border-green-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 4,
    name: 'RedAccent',
    background: 'bg-red-500',
    text: 'text-red-500',
    textLight: 'text-red-200',
    border: 'border-red-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 5,
    name: 'YellowAccent',
    background: 'bg-yellow-500',
    text: 'text-yellow-500',
    textLight: 'text-yellow-200',
    border: 'border-yellow-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 6,
    name: 'BlueAccent',
    background: 'bg-blue-500',
    text: 'text-blue-500',
    textLight: 'text-blue-200',
    border: 'border-blue-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },

  /*   {id: 7, name: "BlackAccent", background: "bg-black", text: "text-black", textLight: "text-blue-200", border: "border-black", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]},
    {id: 8, name: "IndigoAccentDark", background: "bg-[#212246]", text: "text-[#212246]", textLight: "text-[#212246]", border: "border-indigo-200", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]},
    {id: 9, name: "GreenAccentDark", background: "bg-[#0A5954]", text: "text-[#0A5954]", textLight: "text-[#0A5954]", border: "border-indigo-200", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]}, */
];

export const textColors = [
  {
    id: 1,
    name: 'BlackText',
    background: 'bg-gray-900',
    text: 'text-gray-900',
    border: 'border-gray-900',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 2,
    name: 'WhiteText',
    background: 'bg-white',
    text: 'text-white',
    border: 'border-white',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 3,
    name: 'AcreText',
    background: 'bg-acreText',
    text: 'text-acreText',
    border: 'border-acreText',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
];

export const bgColors = [
  {
    id: 1,
    name: 'WhiteBG',
    background: 'bg-white',
    text: 'text-gray-900',
    border: 'border-white',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 2,
    name: 'IndigoBG',
    background: 'bg-indigo-500 bg-opacity-10',
    text: 'text-gray-900',
    border: 'border-indigo-200',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 3,
    name: 'GreenBG',
    background: 'bg-green-500 bg-opacity-10',
    text: 'text-gray-900',
    border: 'border-green-200',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 4,
    name: 'RedBG',
    background: 'bg-red-500 bg-opacity-10',
    text: 'text-gray-900',
    border: 'border-red-200',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 5,
    name: 'YellowBG',
    background: 'bg-yellow-500 bg-opacity-10',
    text: 'text-gray-900',
    border: 'border-orangeBG',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 6,
    name: 'BlueBG',
    background: 'bg-blue-500 bg-opacity-10',
    text: 'text-gray-900',
    border: 'border-blue-200',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },

  {
    id: 7,
    name: 'BlackBG',
    background: 'bg-black',
    text: 'text-white',
    border: 'border-white',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 8,
    name: 'IndigoBGDark',
    background: 'bg-[#2E4056]',
    text: 'text-white',
    border: 'border-indigo-200',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 9,
    name: 'GreenBGDark',
    background: 'bg-[#014745]',
    text: 'text-white',
    border: 'border-green-200',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 10,
    name: 'RedBGColor',
    background: 'bg-[#F48772]',
    text: 'text-white',
    border: 'border-red-200',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },

  {
    id: 11,
    name: 'YellowBGColor',
    background: 'bg-[#FFD18B]',
    text: 'text-gray-900',
    border: 'border-red-200',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 12,
    name: 'BlueBGColor',
    background: 'bg-[#72A2B6]',
    text: 'text-white',
    border: 'border-red-200',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  /*     
    {id: 15, name: "MellowBG", background: "bg-mellow", text: "text-mellow", border: "border-yellow-300", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]},
    {id: 16, name: "SharkBG", background: "bg-sharkSkinLight", text: "text-sharkSkinBorder", border: "border-blue-200", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]},
    {id: 17, name: "ForestBG", background: "bg-forest", text: "text-forestBorder", border: "border-green-200", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]},
    {id: 18, name: "PaperBG", background: "bg-paperBG", text: "text-paperText", border: "border-yellow-200", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]},
    {id: 19, name: "Gard", background: "bg-gradient-to-b from-indigo-500 to-pink-500", text: "text-paperText", border: "border-indigo-500", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]},
    {id: 20, name: "Acre", background: "bg-acreBG", text: "text-acreBG", border: "border-acreBG", plan: ["Free Plan", "Hobby", "Expert", "Expert Lifetime", "Pro", "Premier"]},
 */
];

export const themes = [
  {
    id: 1,
    name: 'Indigo',
    background: 'bg-indigo-500',
    contentBG: 'bg-white dark:bg-black border-gray-100 dark:border-gray-800',
    footer: 'bg-indigo-500 text-indigo-500 bg-opacity-10',
    flat: 'bg-indigo-500',
    text: 'text-indigo-500',
    highlight: 'text-indigo-500',
    border: 'border-indigo-500',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-gray-900 dark:text-white',
    quoteHEX: '#6366F1',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 2,
    name: 'Red',
    background: 'bg-red-500',
    contentBG: 'bg-white dark:bg-black border-gray-100 dark:border-gray-800',
    footer: 'bg-red-500 text-red-500 bg-opacity-10',
    flat: 'bg-red-500',
    text: 'text-red-500',
    highlight: 'text-red-500',
    border: 'border-red-500',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-gray-900 dark:text-white',
    quoteHEX: '#000',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 3,
    name: 'Blue',
    background: 'bg-blue-500',
    contentBG: 'bg-white dark:bg-black border-gray-100 dark:border-gray-800',
    footer: 'bg-blue-500 text-blue-500 bg-opacity-10',
    flat: 'bg-blue-500',
    text: 'text-blue-500',
    highlight: 'text-blue-500',
    border: 'border-blue-500',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-gray-900 dark:text-white',
    quoteHEX: '#000',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 4,
    name: 'Green',
    background: 'bg-green-500',
    contentBG: 'bg-white dark:bg-black border-gray-100 dark:border-gray-900',
    footer: 'bg-green-500 text-green-500 bg-opacity-10',
    flat: 'bg-green-500',
    text: 'text-green-500',
    highlight: 'text-green-500',
    border: 'border-green-500',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-gray-900 dark:text-white',
    quoteHEX: '#000',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 5,
    name: 'Yellow',
    background: 'bg-yellow-400',
    contentBG: 'bg-white dark:bg-black border-gray-100 dark:border-gray-800',
    footer: 'bg-yellow-500 text-yellow-500 bg-opacity-10',
    flat: 'bg-yellow-500',
    text: 'text-yellow-500',
    highlight: 'text-yellow-500',
    border: 'border-yellow-500',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-gray-900 dark:text-white',
    quoteHEX: '#000',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },

  {
    id: 6,
    name: 'Sky',
    background: 'bg-gradient-to-b to-blue-400 from-indigo-500',
    contentBG: 'bg-white dark:bg-black border-gray-100 dark:border-gray-800',
    footer: 'bg-blue-500 text-blue-500 bg-opacity-10',
    flat: 'bg-blue-500',
    border: 'border-blue-500',
    text: 'text-blue-500',
    highlight: 'text-blue-500',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-gray-900 dark:text-white',
    quoteHEX: '#000',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
  {
    id: 7,
    name: 'Rose',
    background: 'bg-gradient-to-b from-purple-400 via-pink-500 to-red-500',
    contentBG: 'bg-white dark:bg-black border-gray-100 dark:border-gray-800',
    footer: 'bg-pink-500 text-pink-500 bg-opacity-10',
    flat: 'bg-pink-500',
    border: 'border-pink-500',
    text: 'text-pink-500',
    highlight: 'text-pink-500',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-gray-900 dark:text-white',
    quoteHEX: '#000',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },

  {
    id: 8,
    name: 'BW',
    background: 'bg-black dark:bg-gray-50',
    contentBG: 'bg-white dark:bg-black border-gray-100 dark:border-gray-800',
    footer: 'bg-gray-500 text-gray-900 dark:text-gray-300 bg-opacity-10',
    text: 'text-black dark:text-white',
    highlight: 'text-blue-500',
    flat: 'bg-gray-500',
    border: 'border-gray-500',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-gray-900 dark:text-white',
    quoteHEX: '#000',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },

  {
    id: 9,
    name: 'Mellow',
    background: 'bg-mellowBorder dark:bg-crimson',
    contentBG:
      'bg-mellow dark:bg-gray-900 border-mellowBorder dark:border-gray-800',
    footer: 'bg-yellow-500 text-yellow-600 bg-opacity-10',
    flat: 'bg-yellow-500',
    text: 'text-crimson dark:text-crimsonDark',
    highlight: 'text-yellow-600',
    border: 'border-yellow-500',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-crimson dark:text-crimsonDark',
    quoteHEX: '#000',
    quoteBORDER: '#fff',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },

  {
    id: 10,
    name: 'Shark',
    background: 'bg-sharkSkin dark:bg-sharkBorderDark',
    contentBG:
      'bg-sharkSkinLight dark:bg-sharkDark border-sharkSkin dark:border-sharkBorderDark',
    footer: 'bg-blue-500 text-blue-900 dark:text-blue-500 bg-opacity-10',
    flat: 'bg-blue-500',
    text: 'text-blue-900 dark:text-sharkSkinLight',
    highlight: 'text-blue-900 dark:text-blue-500',
    border: 'border-blue-900',
    page: 'bg-white',
    pageDark: 'bg-black',
    quoteHEX: '#000',
    bodyText: 'text-sharkBorderDark dark:text-sharkSkinLight',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },

  {
    id: 11,
    name: 'Forest',
    background: 'bg-forestBorder dark:bg-forestBorderDark',
    contentBG:
      'bg-forest dark:bg-forestDark border-forestBorder dark:border-forestBorderDark',
    footer: 'bg-green-500 text-green-900 dark:text-green-500 bg-opacity-10',
    flat: 'bg-green-500',
    text: 'text-green-900 dark:text-forest',
    highlight: 'text-green-900 dark:text-green-500',
    border: 'border-green-900',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-forestBorderDark dark:text-forest',
    quoteHEX: '#10B98110',
    quoteBORDER: '#BBD7C6',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },

  {
    id: 12,
    name: 'Paper',
    background: 'bg-paperColor',
    contentBG:
      'bg-paperBG dark:bg-gray-900 border-mellowBorder dark:border-gray-800',
    footer: 'text-paperColor bg-paperColor bg-opacity-10',
    flat: 'bg-Color',
    text: 'text-paperText dark:text-crimsonDark',
    highlight: 'text-paperColor',
    border: 'border-paperText dark:border-crimsonDark',
    page: 'bg-white',
    pageDark: 'bg-black',
    bodyText: 'text-forestBorderDark dark:text-forest',
    quoteHEX: '#10B98110',
    quoteBORDER: '#BBD7C6',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
  },
];

export const colorTemplates = [
  //insert a hex object hex.title and hex.text using the cooresponding hex values for the tailwind color template
  {
    id: 1,
    name: 'Indigo',
    gradientTO: 'to-indigo-500',
    buttonBG: 'bg-indigo-500',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-white dark:bg-black',
    title: 'text-indigo-500',
    footer: {
      background: 'bg-indigo-500 bg-opacity-10',
      text: 'text-indigo-500',
    },
    text: 'text-gray-900',
    authorAsBG: 'bg-indigo-500',
    textAsBG: 'bg-gray-900',
    author: 'text-gray-500',
    preview: 'bg-indigo-500 border-indigo-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#6366f1', text: '#111827', bg: '#fff' },
  },
  {
    id: 2,
    name: 'Red',
    buttonBG: 'bg-red-500',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-white dark:bg-black',
    title: 'text-red-500',
    footer: { background: 'bg-red-500 bg-opacity-10', text: 'text-red-500' },
    text: 'text-gray-900',
    authorAsBG: 'bg-red-500',
    textAsBG: 'bg-gray-900',
    author: 'text-gray-500',
    preview: 'bg-red-500 border-red-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#ef4444', text: '#111827', bg: '#fff' },
  },
  {
    id: 3,
    name: 'Blue',
    buttonBG: 'bg-blue-500',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-white dark:bg-black',
    title: 'text-blue-500',
    footer: { background: 'bg-blue-500 bg-opacity-10', text: 'text-blue-500' },
    text: 'text-gray-900',
    authorAsBG: 'bg-blue-500',
    textAsBG: 'bg-gray-900',
    author: 'text-gray-500',
    preview: 'bg-blue-500 border-blue-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#3b82f6', text: '#111827', bg: '#fff' },
  },
  {
    id: 4,
    name: 'Green',
    buttonBG: 'bg-green-500',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-white dark:bg-black',
    title: 'text-green-500',
    footer: {
      background: 'bg-green-500 bg-opacity-10',
      text: 'text-green-500',
    },
    text: 'text-gray-900',
    authorAsBG: 'bg-green-500',
    textAsBG: 'bg-gray-900',
    author: 'text-gray-500',
    preview: 'bg-green-500 border-green-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#22c55e', text: '#111827', bg: '#fff' },
  },
  {
    id: 5,
    name: 'Yellow',
    buttonBG: 'bg-yellow-500',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-white dark:bg-black',
    title: 'text-yellow-500',
    footer: {
      background: 'bg-yellow-500 bg-opacity-10',
      text: 'text-yellow-500',
    },
    text: 'text-gray-900',
    authorAsBG: 'bg-yellow-500',
    textAsBG: 'bg-gray-900',
    author: 'text-gray-500',
    preview: 'bg-yellow-500 border-yellow-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#eab308', text: '#111827', bg: '#fff' },
  },

  {
    id: 6,
    name: 'Sky',
    buttonBG: 'bg-blue-400',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-blue-50',
    title: 'text-blue-400',
    footer: { background: 'bg-blue-400 bg-opacity-10', text: 'text-blue-400' },
    text: 'text-gray-900',
    author: 'text-gray-500',
    textAsBG: 'bg-gray-900',
    preview: 'bg-blue-300',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#60a5fa', text: '#111827', bg: '#eff6ff' },
  },
  {
    id: 7,
    name: 'Rose',
    buttonBG: 'bg-pink-500',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-orange-50',
    title: 'text-pink-400',
    footer: { background: 'bg-pink-400 bg-opacity-10', text: 'text-pink-400' },
    text: 'text-gray-900',
    textAsBG: 'bg-gray-900',
    author: 'text-gray-500',
    preview: 'bg-pink-300',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#f472b6', text: '#111827', bg: '#fff7ed' },
  },
  {
    id: 8,
    name: 'BW',
    buttonBG: 'bg-gray-900',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-white dark:bg-black',
    title: 'text-gray-900 dark:text-white',
    footer: { background: 'bg-gray-900 bg-opacity-10', text: 'text-gray-500' },
    text: 'text-gray-900',
    author: 'text-gray-500',
    authorAsBG: 'bg-gray-500',
    textAsBG: 'bg-gray-900',
    preview: 'bg-gray-900 border-gray-900',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#111827', text: '#111827', bg: '#fff' },
  },

  {
    id: 9,
    name: 'Mellow',
    buttonBG: 'bg-crimson',
    buttonText: 'text-mellow',
    buttonLine: 'bg-mellow',
    background: 'bg-mellow',
    title: 'text-crimson',
    author: 'text-crimson',
    authorAsBG: 'bg-crimson',
    footer: {
      background: 'bg-yellow-500 bg-opacity-10',
      text: 'text-yellow-600',
    },
    text: 'text-crimson',
    textAsBG: 'bg-crimson',
    preview: 'bg-mellowBorder border-mellowBorder',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#50321B', text: '#50321B', bg: '#F8F1E4' },
  },

  {
    id: 10,
    name: 'Shark',
    buttonBG: 'bg-blue-900',
    buttonText: 'text-sharkSkinLight',
    buttonLine: 'bg-sharkSkinLight',
    background: 'bg-sharkSkinLight',
    title: 'text-blue-900',
    author: 'text-blue-900',
    authorAsBG: 'bg-blue-900',
    footer: { background: 'bg-blue-500 bg-opacity-10', text: 'text-blue-900' },
    text: 'text-blue-900',
    textAsBG: 'bg-blue-900',
    preview: 'bg-sharkSkin border-sharkSkin',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#1e3a8a', text: '#1e3a8a', bg: '#EAF4F6' },
  },

  {
    id: 11,
    name: 'Forest',
    buttonBG: 'bg-green-900',
    buttonText: 'text-forest',
    buttonLine: 'bg-forest',
    background: 'bg-forest',
    title: 'text-green-900',
    author: 'text-green-900',
    authorAsBG: 'bg-green-900',
    footer: {
      background: 'bg-green-500 bg-opacity-10',
      text: 'text-green-900',
    },
    text: 'text-green-900',
    textAsBG: 'bg-paperText',
    preview: 'bg-forestBorder border-forestBorder',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#14532d', text: '#14532d', bg: '#EAF6EC' },
  },
  {
    id: 12,
    name: 'Paper',
    buttonBG: 'bg-paperText',
    buttonText: 'text-paperBG',
    buttonLine: 'bg-paperBG',
    background: 'bg-paperBG',
    title: 'text-paperText',
    author: 'text-paperText',
    authorAsBG: 'bg-paperText',
    footer: {
      background: 'bg-paperColor bg-opacity-10',
      text: 'text-paperColor',
    },
    text: 'text-paperText',
    textAsBG: 'bg-paperText',
    preview: 'bg-paperColor border-paperColor',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#22243D', text: '#22243D', bg: '#EAE5D6' },
  },
  {
    id: 13,
    name: 'Acre',
    buttonBG: 'bg-[#F4D03E]',
    buttonText: 'text-[#2E4056]',
    buttonLine: 'bg-[#2E4056]',
    background: 'bg-[#2E4056]',
    title: 'text-[#F4D03E]',
    author: 'text-[#F4D03E]',
    footer: {
      background: 'bg-[#F0A89F] bg-opacity-10',
      text: 'text-[#F4D03E]',
    },
    text: 'text-[#F0A89F]',
    textAsBG: 'bg-[#F0A89F]',
    authorAsBG: 'bg-[#F4D03E]',
    preview: 'bg-[#2E4056] border-[#2E4056]',
    plan: ['Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#F0A89F', text: '#F0A89F', bg: '#2E4056' },
  },
  {
    id: 14,
    name: 'Rose2',
    buttonBG: 'bg-pink-500',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-white dark:bg-black',
    title: 'text-pink-500',
    footer: { background: 'bg-pink-500 bg-opacity-10', text: 'text-pink-500' },
    text: 'text-gray-900',
    textAsBG: 'bg-gray-900',
    author: 'text-pink-500',
    preview: 'bg-pink-500',
    plan: ['Free Plan', 'Hobby', 'Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#ec4899', text: '#111827', bg: '#fff' },
  },

  {
    id: 15,
    name: 'Coral',
    buttonBG: 'bg-[#FF8788]',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-[#005397]',
    title: 'text-[#FF8788]',
    footer: {
      background: 'bg-[#FF8788] bg-opacity-10',
      text: 'text-[#FF8788]',
    },
    text: 'text-[#ABCEE2]',
    textAsBG: 'bg-white',
    author: 'text-[#FF8788]',
    preview: 'bg-[#FF8788]',
    plan: ['Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#FF8788', text: '#fff', bg: '#005397' },
  },
  {
    id: 16,
    name: 'Chocolate',
    buttonBG: 'bg-[#BBAB9B]',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-[#371722]',
    title: 'text-[#BBAB9B]',
    footer: {
      background: 'bg-[#BBAB9B] bg-opacity-10',
      text: 'text-[#BBAB9B]',
    },
    text: 'text-[#ABCEE2]',
    textAsBG: 'bg-white',
    author: 'text-[#FFFFFF]',
    preview: 'bg-[#BBAB9B]',
    plan: ['Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#BBAB9B', text: '#fff', bg: '#371722' },
  },
  {
    id: 17,
    name: 'Moss',
    buttonBG: 'bg-[#766C08]',
    buttonText: 'text-white',
    buttonLine: 'bg-white',
    background: 'bg-[#E7E7E7]',
    title: 'text-[#766C08]',
    footer: {
      background: 'bg-[#766C08] bg-opacity-10',
      text: 'text-[#766C08]',
    },
    text: 'text-[#ABCEE2]',
    textAsBG: 'bg-[#DA9F18]',
    author: 'text-[#DA9F18]',
    preview: 'bg-[#766C08]',
    plan: ['Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#766C08', text: '#766C08', bg: '#E7E7E7' },
  },
  {
    id: 18,
    name: 'Clover',
    buttonBG: 'bg-[#FEC8BE]',
    buttonText: 'text-gray-900',
    buttonLine: 'bg-gray-900',
    background: 'bg-[#20AD65]',
    title: 'text-[#FEC8BE]',
    footer: {
      background: 'bg-[#FEC8BE] bg-opacity-10',
      text: 'text-[#FEC8BE]',
    },
    text: 'text-[#ABCEE2]',
    textAsBG: 'bg-[#fff]',
    author: 'text-[#FEC8BE]',
    preview: 'bg-[#FEC8BE]',
    plan: ['Expert', 'Expert Lifetime', 'Pro', 'Premier'],
    hex: { title: '#FEC8BE', text: '#fff', bg: '#20AD65' },
  },
];
